class AboutHandle {
  formToJso(formData) {
    return {
      id: formData.id,
      name: formData.name,
      email: formData.email,
      password: formData.password,
      nivel: formData.nivel,
      status: formData.status,
    };
  }

  formToJsoPass(formData) {
    return {
      id: formData.id,
      password: formData.password,
      password2: formData.password2,
    };
  }
}

export default new AboutHandle();
