import axios from 'axios';

import {URL_API} from './Commons'

class AuthService {
  login(user) {
    return axios
      .post(URL_API+'login', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        // console.log("response");
        // console.log(response);
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.setItem('user', JSON.stringify({}));
    localStorage.removeItem('user');
    // return axios
    //   .post(URL_API+'/logout', {
    //     email: user.email,
    //     password: user.password
    //   })
    //   .then(response => {
    //     if (response.data.jwt) {
    //       localStorage.setItem('user', JSON.stringify(response.data));
    //     }

    //     return response.data;
    //   });
    
  }
}

export default new AuthService();