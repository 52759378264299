<template>
  <div>
    <div id="nav">
      <top-menu></top-menu>
    </div>
    <router-view />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import TopMenu from "@/components/TopMenu.vue";
import Footer from "@/components/Footer.vue";

// import { useI18n } from 'vue-i18n'
//  import { getCurrentInstance } from 'vue'
export default {
  name: "App",
  // setup(){

  //    const app = getCurrentInstance()

  //   const changeLanguage = () => {
  //     app.appContext.config.globalProperties.$changei18n("br")
  //     localStorage.locale = "br"
  //   }
  //   return {changeLanguage}
  // },
  components: {
    TopMenu,
    Footer,
  },
};
</script>

<style lang="stylus"></style>
