<template>
  <div>
    <footer class="footer text-center mt-1 mb-2 py-4 text-muted">
      <div class="container">
        Desenvolvido com o
        <i class="bi bi-heart-fill text-danger" title="Corazón"></i> por
        <a href="https://vrclic.com.br" target="_blank" class="text-dark"
          >VRCLIC - Soluções Digitais <i class="far fa-registered"></i></a
        >
        2010 - {{new Date().getFullYear()}} / Direitos Reservados
      </div>
    </footer>
  </div>
</template>
