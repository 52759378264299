export default function authHeader() {
    let dataUser = JSON.parse(localStorage.getItem('user'));
    if (dataUser && dataUser.access_token) {
      return { 
        "Access-Control-Allow-Origin": "*",
        "Content-type": "Application/json",
        "Authorization": dataUser.token_type+' ' + dataUser.access_token };
    } else {
      return {};
    }
  } 