<template>
  <div class="topmenu">
    <nav class="navbar navbar-expand-lg navbar-dark bg-ideias p-3" :key="$store.meuKeyReload">
      <div class="container">
        <a class="navbar-brand" href="/"
          >INSTITUTO IDEIAS</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav">
            <li class="nav-item active text-center py-2" v-if="userActual.id">
              <a class="nav-link" href="/"
                ><i class="bi bi-house"></i> {{ $t("home") }}</a
              >
            </li>                       
            <li class="nav-item text-center" v-if="userActual.id">
              <a class="nav-link" href="/estudo"
                ><i class="bi bi-file-earmark-bar-graph"></i> Santa Helena<br/> em Números                
                </a
              >
            </li>
            <li class="nav-item text-center" v-if="userActual.id">
              <a class="nav-link" href="/artigo"
                ><i class="bi bi-file-earmark-font"></i> Observatório<br/> Social </a
              >
            </li>
            <li class="nav-item text-center py-2" v-if="userActual.id">
              <a class="nav-link" href="/notadiaria"
                ><i class="bi bi-file-earmark-medical"></i> {{ $t("dailyNotes") }}</a
              >
            </li>
            <li class="nav-item text-center py-2" v-if="userActual.id">
              <a class="nav-link" href="/categoria"
                ><i class="bi bi-card-list"></i> {{ $t("categories") }}</a
              >
            </li>
            <li class="nav-item text-center py-2" v-if="userActual.id">
              <a class="nav-link" href="/apoio"
                ><i class="bi bi-hand-thumbs-up"></i> {{ $t("sponsorships") }}</a
              >
            </li>
            <li class="nav-item text-center py-2" v-if="userActual.id">
              <a class="nav-link" href="/links"
                ><i class="bi bi-box-arrow-up-right"></i> {{ $t("links") }}</a
              >
            </li>
            <li class="nav-item text-center py-2" v-if="userActual.id">
              <a class="nav-link" href="/enquete"
                ><i class="bi bi-card-checklist"></i> {{ $t("Surveys") }}</a
              >
            </li>
             <li class="nav-item text-center py-2" v-if="userActual.id">
              <a class="nav-link" href="/about"
                ><i class="bi bi-briefcase"></i> {{ $t("about") }}</a
              >
            </li>           
            <li class="nav-item text-center py-2" v-if="userActual.id">
              <a class="nav-link" href="/user"
                ><i class="bi bi-people"></i> {{ $t("users") }}</a
              >
            </li>

            <!-- <li class="nav-item mt-2 text-center">
              <select
                class="bg-ideias border border-white text-dark"
                v-model="$i18n.locale"
                @change="changeLanguage($i18n.locale)"
              >
                <option
                  v-for="locale in $i18n.availableLocales"
                  :key="`locale-${locale}`"
                  :value="locale"
                >
                  {{ locale }}
                </option>
              </select>
            </li> -->

            <li class="nav-item dropdown text-center" v-if="userActual.id">
              <div class="btn-group">
                <button
                  class="btn btn-menu dropdown-toggle text-dark py-2"
                  type="button"
                  id="profile"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-person-circle text-blue" style="font-size:1.4em"></i>
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="profile"
                  role="menu"
                >
                  <li class="ms-3 small-sm">
                    <i class="bi bi-person-circle"></i> {{ userActual.name }}
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item" @click="openModalProfile"
                      ><i class="bi bi-person-rolodex"></i>
                      {{ $t("account") }}</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" @click="openModalPassword"
                      ><i class="bi bi-shield-lock"></i>
                      {{ $t("changePass") }}</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" @click="sair"
                      ><i class="bi bi-box-arrow-right"></i> {{ $t("quit") }}</a
                    >
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <vr-toast :modelValue="toastList" :key="toastList"></vr-toast>
    <!-- Start Modal Edit -->
    <div class="modal fade" ref="editModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div v-if="deleteMode" class="modal-header bg-danger text-white">
            <h5 class="modal-title" id="editModalLabel">
              <i class="bi bi-eraser-fill"></i> {{ $t("delete") }}
              {{ $t("user") }}
            </h5>
            <a href="#" class="text-white" @click="modalEdit.hide()"
              ><i class="bi bi-x-lg"></i
            ></a>
          </div>
          <div v-if="!deleteMode" class="modal-header bg-warning">
            <h5 class="modal-title" id="editModalLabel">
              <i class="bi bi-pencil"></i> {{ $t("edit") }} {{ $t("user") }}
            </h5>
            <a href="#" class="text-dark" @click="modalEdit.hide()"
              ><i class="bi bi-x-lg"></i
            ></a>
          </div>
          <div class="modal-body">            
            <form-edit-user
              :dataItem="userActual"
              :actionSend="sendEdit"
              :errorServer="errorServer"
              :key="keyFormEditUser"
              :ActionSendFile="sendEdit"
              :deleteMode="deleteMode"
              :toastShow="toastShow"
            >
            </form-edit-user>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal Edit -->
    <!-- Start Modal Password -->
    <div class="modal fade" ref="editPassword" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-azul">
            <h5 class="modal-title" id="editModalLabel">
              <i class="bi bi-boxes"></i> {{ $t("user") }} x
              {{ $t("password") }}
            </h5>
            <a href="#" class="text-white" @click="modalPassword.hide()"
              ><i class="bi bi-x-lg"></i
            ></a>
          </div>
          <div class="modal-body">
            <form-password-user
              :dataItem="userActual"
              :actionSend="sendEdit"
              :errorServer="errorServer"
              :key="keyFormPasswordUser"
              :ActionSendFile="sendEdit"
              :deleteMode="deleteMode"
              :toastShow="toastShow"
            >
            </form-password-user>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal Password -->
  </div>
</template>

<script>
import UserService from "@/services/user.service"; 
import FormEditUser from "@/views/protected/pages/user/comp/formEditUser.vue";
import FormPasswordUser from "@/views/protected/pages/user/comp/formPassUser.vue";
import VrToast from "@/components/VrToast.vue";
import { Modal } from "bootstrap";
import { getCurrentInstance } from "vue";

export default {
  data: () => ({
    userLoged: {},
    changeLanguage: null,
    keyFormEditUser: "",
    keyFormPasswordUser: "",
    modalEdit: null,
    modalPassword: null,
    toastList: [],
    deleteMode: false,
    app: null,
  }),
  components: {
    VrToast,
    FormEditUser,
    FormPasswordUser,
  },
  mounted() {
    this.changeLanguage = (lang) => {
      localStorage.locale = lang;
    };
    this.modalEdit = new Modal(this.$refs.editModal);
    this.modalPassword = new Modal(this.$refs.editPassword);
    this.app = getCurrentInstance();
    this.reloadData();
    this.app.appContext.config.globalProperties.$store.commit("incrementMeuKeyReload");
  },
  methods: {
    reloadData: function (){  
      let storageUserData = JSON.parse(localStorage.getItem('user'));
      this.userLoged = storageUserData.user;
      UserService.getLoged().then((result) => {
        console.log(result);
        // this.userLoged = result.data;
        //this.app.appContext.config.globalProperties.$keyMenuFunction(Math.random().toString(36).slice(2));
      },
      (error) => { 
        console.log(error);
        this.app.appContext.config.globalProperties.$store
          .dispatch("auth/logout")
          .then(
            () => {
              this.app.appContext.config.globalProperties.$router.go(0);
            },
            (error2) => {
              console.log(error2);
            }
          );
      });
      this.modalEdit.hide();
      this.modalPassword.hide();
      //this.app.appContext.config.globalProperties.$keyMenuFunction(Math.random().toString(36).slice(2));
    },
    openModalPassword: function () {
      this.keyFormPasswordUser = Math.random().toString(36).slice(2);
      this.modalPassword.show();
    },
    openModalProfile: function () {
      this.keyFormEditUser = Math.random().toString(36).slice(2);
      this.modalEdit.show();
    },
    sendEdit: function (objectForm) {
      this.toastShow({ msg: objectForm.msg, style: objectForm.style });
      
      this.reloadData();
    },
    toastShow: function (alert) {
      this.toastList = [];
      this.toastList.push(alert);
    },
    errorServer: function (error) {
      this.toastShow({ msg: "Falha ao enviar dados (403)", style: "danger" });
      if (error.message.includes("403")) {
        this.app.appContext.config.globalProperties.$router.push("/login");
      }
    },
    sair: function (event) {
      event.preventDefault();
      this.app.appContext.config.globalProperties.$store
          .dispatch("auth/logout")
          .then(
            () => {
              this.app.appContext.config.globalProperties.$router.go(0);
            },
            (error) => {
              console.log(error);
            }
          );
    },
  },
  computed: {
    userActual() {
      return this.userLoged;
    },
  },
  // setup() {
  //   const changeLanguage = (lang) => {
  //     localStorage.locale = lang;
  //   };
  //   UserService.getLoged().then((result) => {
  //     this.userLoged = result.data;
  //   });
  //   return { changeLanguage };
  // },
};
</script>


<style scoped lang="stylus"></style>
