import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/protected/pages/about/About.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/categoria',
        name: 'Categoria',
        component: () =>
            import ('../views/protected/pages/categoria/Categoria.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/estudo',
        name: 'Estudo',
        component: () =>
            import ('../views/protected/pages/estudo/Estudo.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/artigo',
        name: 'Artigo',
        component: () =>
            import ('../views/protected/pages/artigo/Artigo.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/notadiaria',
        name: 'NotaDiaria',
        component: () =>
            import ('../views/protected/pages/notadiaria/NotaDiaria.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/apoio',
        name: 'Apoio',
        component: () =>
            import ('../views/protected/pages/apoio/Apoio.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/links',
        name: 'LinksUteis',
        component: () =>
            import ('../views/protected/pages/linksuteis/LinksUteis.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/enquete',
        name: 'Enquete',
        component: () =>
            import ('../views/protected/pages/enquete/Enquete.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/public/Login.vue')
    },
    {
        path: '/logado',
        name: 'Logado',
        component: () =>
            import ('../views/protected/Logado.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/user',
        name: 'User',
        component: () =>
            import ('../views/protected/pages/user/User.vue'),
        meta: { requiresAuth: true }
    },
]



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {

    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    // console.log(loggedIn)
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router