import { createStore } from 'vuex'

import { auth } from './auth.module';

export default createStore({
  modules: {
    auth
  },
  state: {
    meuKeyReload: 0,
  },
  mutations: {
    incrementMeuKeyReload (state) {
      state.meuKeyReload++
    }
  },
  actions: {
  }
})
