import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { createI18n } from 'vue-i18n'

import en from './translations/en.json'
import es from './translations/es.json'
import br from './translations/br.json'

const i18n = createI18n({
    locale: 'br',
    fallbackLocale: 'br',
    messages: {
        en: en,
        es: es,
        br: br
    },
    useScope: 'global'
})

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './assets/css/font-awesome.min.css'
import './assets/css/style.css';

let app = createApp(App)

app.config.globalProperties.$changei18n = (locale) => {
    i18n.global.locale = locale;
}

if (localStorage.locale) {
    i18n.global.locale = localStorage.locale;
}

let keyMenuReload = Math.random().toString(36).slice(2);
const keyMenuFunction = (keyRandom) => {
    keyMenuReload = keyRandom;
}


app.use(store).use(router).use(i18n).use(keyMenuFunction).use(keyMenuReload).mount('#app')