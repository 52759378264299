<template>
  <div class="hello">
    <div class="Logado">
      <!-- <vr-toast :modelValue="toastList" :key="toastList"></vr-toast> -->
      <div class="container">
        <div class="row p-1 mt-2 bg-light rounded">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mt-2">
              <li class="breadcrumb-item active" aria-current="page">
                {{ $t("home") }}
              </li>
            </ol>
          </nav>
        </div>

        <div class="row mt-4">
          <div class="col-4 bg-light p-2 border">
            <div class="row">
              <div class="col-4 text-center">
                <a class="nav-link" href="/estudo"
                  ><i
                    class="bi bi-file-earmark-bar-graph"
                    style="font-size: 2.8em"
                  ></i
                  ><br />
                  Santa Helena em Números
                </a>
              </div>
              <div class="col-4 text-center">
                <a class="nav-link" href="/artigo"
                  ><i
                    class="bi bi-file-earmark-font"
                    style="font-size: 2.8em"
                  ></i
                  ><br />
                  Observatório Social
                </a>
              </div>
              <div class="col-4 text-center">
                <a class="nav-link" href="/notadiaria"
                  ><i
                    class="bi bi-file-earmark-medical"
                    style="font-size: 2.8em"
                  ></i
                  ><br />
                  {{ $t("dailyNotes") }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-4 bg-light p-3 border">
            <div class="row">
              <div class="col-4 text-center">
                <a class="nav-link" href="/categoria"
                  ><i class="bi bi-card-list" style="font-size: 2.8em"></i
                  ><br />
                  {{ $t("categories") }}</a
                >
              </div>
              <div class="col-4 text-center">
                <a class="nav-link" href="/apoio"
                  ><i class="bi bi-hand-thumbs-up" style="font-size: 2.8em"></i
                  ><br />
                  {{ $t("sponsorships") }}</a
                >
              </div>
              <div class="col-4 text-center">
                <a class="nav-link" href="/links"
                  ><i
                    class="bi bi-box-arrow-up-right"
                    style="font-size: 2.8em"
                  ></i
                  ><br />
                  {{ $t("links") }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-4 bg-light p-3 border">
            <div class="row">
              <div class="col-6 text-center">
                <a class="nav-link" href="/enquete"
                ><i class="bi bi-card-checklist" style="font-size: 2.8em"
                  ></i
                  ><br /> {{ $t("Surveys") }}</a
              >
              </div>
              <div class="col-6 text-center">
                <a class="nav-link" href="/about"
                ><i class="bi bi-briefcase" style="font-size: 2.8em"
                  ></i
                  ><br /> {{ $t("about") }}</a
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
