<template>
  <div>
    <div aria-live="polite" aria-atomic="true" class="position-relative">
      <div class="toast-container position-absolute top-0 end-0 p-2">
        <div
          :class="'bg-' + item.style + ' toast toastAlert'"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          v-for="item in modelValue"
          :key="item"
        >
          <div class="d-flex">
            <div class="toast-body text-white">
              {{ item.msg }}
            </div>
            <button
              type="button"
              class="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "bootstrap";
export default {
  props: ["modelValue"],
  name: "VrToast",
  data: () => ({
    toastLive: null,
  }),
  mounted() {
    //toastList
    var toastElList = [].slice.call(document.querySelectorAll(".toastAlert"));
    var toastList = toastElList.map(function (toastEl) {
      return new Toast(toastEl);
    });
    toastList.forEach((element) => {
      element.show();
    });

    // if(toastList.length > 0){
    //     toastList[0].show();
    // }
  },
};
</script>

<style>
</style>