<template>
  <div>
    <form @submit="sendEdit">
      <div class="mb-3">
        <label for="password_input" class="form-label">{{
          $t("passUser")
        }}</label>
        <input
          type="password"
          class="form-control"
          v-model="password"
          aria-describedby="password_help"
          placeholder="Nova Senha - Mínimo 9 Digitos"
        />
      </div>
      <div class="mb-3">
        <label for="password2_input" class="form-label">{{
          $t("passrUser")
        }}</label>
        <input
          type="password"
          class="form-control"
          v-model="password2"
          aria-describedby="password2_help"
          placeholder="Repita a Senha - Mínimo 9 Digitos"
        />
      </div>
      <div class="d-grid gap-2">
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="!loading"
        >
          {{ $t("submit") }} <i class="bi bi-save"></i>
        </button>
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="loading"
          disabled
        >
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ $t("loading") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import UserService from "../../../../../services/user.service";
import UserHandle from "../../../../../services/handle/user.handle";
export default {
  props: ["dataItem", "actionSend", "errorServer", "deleteMode", "toastShow"],
  data: () => ({
    formEdit: {},
    urlImg: "",
    loading: false,
    password: "",
    password2: "",
  }),
  mounted() {
    this.formEdit = this.dataItem;
    console.log(this.formEdit);
  },
  methods: {
    sendEdit: function (event) {
      event.preventDefault();
      this.loading = true;
      if (
        this.password &&
        this.password2 &&
        this.password === this.password2 &&
        this.password.length > 8
      ) {
        this.formEdit.password = this.password,
        UserService.updatePass(
          this.formEdit.id,
          UserHandle.formToJsoPass(this.formEdit)
        ).then(
          (result) => {
            console.log(result);
            this.loading = false;
            this.actionSend({ msg: "O item foi alterado com Sucesso.", style: "success" });
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
      }else{
        this.toastShow({ msg: "A Senha é inválida", style: "danger" });
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>