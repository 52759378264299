import axios from "axios";
import authHeader from "./auth-header";

import { URL_API } from "./Commons";

class UserService {
  async getAll() {
    return await axios.get(URL_API + "users", { headers: authHeader() });
  }

  getOne(id) {
    return axios.get(URL_API + "user/" + id, { headers: authHeader() });
  }

  insert(user) {
    return axios.post(URL_API + "user", user, { headers: authHeader() });
  }

  update(id, user) {
    return axios.put(URL_API + "user/" + id, user, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(URL_API + "user/" + id, { headers: authHeader() });
  }

  updatePass(id, user) {
    return axios.put(URL_API + "userpass/" + id, user, {
      headers: authHeader(),
    });
  }

  updateLoged(id, user) {
    return axios.post(URL_API + "user/loged/profile/" + id, user, {
      headers: authHeader(),
    });
  }

  updateLogedPass(id, user) {
    return axios.post(URL_API + "user/loged/password/" + id, user, {
      headers: authHeader(),
    });
  }

  getLoged() {
    return axios.get(URL_API + "userprofile", { headers: authHeader() });
  }
}

export default new UserService();
