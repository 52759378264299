<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb text-muted justify-content-end small">
        <li class="breadcrumb-item">
          {{ $t("id") }}:
          <span class="badge bg-secondary">{{ formEdit.id }}</span>
        </li>
        <li class="breadcrumb-item">
          {{ $t("dateInsert") }}:
          <span class="badge bg-secondary" v-if="moment">
            {{ moment(formEdit.created_at).format("DD/MM/YYYY") }}
          </span>
        </li>
      </ol>
    </nav>
    <form @submit="sendEdit">
      <div class="mb-3">
        <label for="name_input" class="form-label">{{ $t("nameUser") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.name"
          aria-describedby="name_help"
        />
      </div>
      <div class="mb-3">
        <label for="email_input" class="form-label">{{
          $t("emailUser")
        }}</label>
        <input
          disabled
          type="text"
          class="form-control"
          v-model="formEdit.email"
          aria-describedby="email_help"
        />
      </div>

      <div class="row">
        <div class="col-6 mb-3">
          <label for="nivel_input" class="form-label">{{
            $t("levelUser")
          }}</label>
          <!-- <input
          type="text"
          class="form-control"
          v-model="formInclude.nivel"
          aria-describedby="nivel_help"
        /> -->
          <select
            class="form-select"
            @change="formEdit.nivel = $event.target.value"
            v-model="formEdit.nivel"
          >
            <option value="9">ADMIN</option>
            <option value="1">Redator</option>
          </select>
        </div>
        <div class="col-6 mb-3">
          <label for="lang_input" class="form-label">{{ $t("status") }}</label>
          <select
            class="form-select"
            @change="formEdit.status = $event.target.value"
            v-model="formEdit.status"
          >
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
        </div>
      </div>
      <div class="d-grid gap-2">
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="!loading"
        >
          {{ $t("submit") }} <i class="bi bi-save"></i>
        </button>
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="loading"
          disabled
        >
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ $t("loading") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import UserService from "../../../../../services/user.service";
import UserHandle from "../../../../../services/handle/user.handle";
import moment from "moment";
export default {
  props: [
    "dataItem",
    "actionSend",
    "ActionSendFile",
    "errorServer",
    "deleteMode",
    "admin",
  ],
  data: () => ({
    formEdit: {},
    urlImg: "",
    loading: false,
    moment: null,
  }),
  mounted() {
    this.moment = moment;
    this.moment.locale("pt-br");
    this.formEdit = this.dataItem;
    console.log(this.formEdit);
  },
  methods: {
    sendEdit: function (event) {
      event.preventDefault();
      this.loading = true;
      if (this.deleteMode) {
        console.log(this.formEdit);
        UserService.delete(this.formEdit.id).then(
          (result) => {
            console.log(result);
            this.loading = false;
            this.actionSend({
              msg: "O item foi excluído com Sucesso.",
              style: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
        return;
      }
      UserService.update(
        this.formEdit.id,
        UserHandle.formToJso(this.formEdit)
      ).then(
        (result) => {
          console.log(result);
          this.loading = false;
          this.actionSend({
            msg: "O item foi alterado com Sucesso.",
            style: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.errorServer(error);
        }
      );
    },
  },
};
</script>

<style>
</style>